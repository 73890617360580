const Icons = {
  fiddler: require("../Assets/Icons/fidder.png"),
  fiddler1: require("../Assets/Icons/fidder1.png"),
  fiddler2: require("../Assets/Icons/fidder2.png"),
  fiddler3: require("../Assets/Icons/fidder3.png"),
  fiddler4: require("../Assets/Icons/fidder4.png"),
  fiddler5: require("../Assets/Icons/fidder5.png"),
  fiddler6: require("../Assets/Icons/fidder6.png"),
  fiddler7: require("../Assets/Icons/fidder7.png"),
  fiddler8: require("../Assets/Icons/fidder8.png"),
  fiddler9: require("../Assets/Icons/fidder9.png"),
  fiddler10: require("../Assets/Icons/fidder10.png"),
  fiddler11: require("../Assets/Icons/fidder11.png"),
  fiddler12: require("../Assets/Icons/fidder12.png"),
  fiddler13: require("../Assets/Icons/fidder13.png"),
  lacfiddler: require("../Assets/Icons/lacfiddler.jpg"),
  Rectangle1: require("../Assets/Icons/Rectangle1.jpg"),
  Rectangle4: require("../Assets/Icons/Rectangle4.png"),
  Vectorcagaru: require("../Assets/Icons/Vectorcagaru.png"),
  Vectorrish: require("../Assets/Icons/Vectorrish.png"),
  Tira: require("../Assets/Icons/Tira.png"),
  Line: require("../Assets/Icons/Line.png"),
  evenements1: require("../Assets/Icons/Foranyoccasion/evenements1.jpg"),
  evenements2: require("../Assets/Icons/Foranyoccasion/new_evenements2.jpg"),
  evenements3: require("../Assets/Icons/Foranyoccasion/evenements3.jpg"),
  evenements4: require("../Assets/Icons/Foranyoccasion/evenements4.jpg"),
  evenements5: require("../Assets/Icons/Foranyoccasion/evenements5.jpg"),
  family: require("../Assets/Icons/Foranyoccasion/family.png"),
  family1: require("../Assets/Icons/Foranyoccasion/family1.png"),
  family2: require("../Assets/Icons/Foranyoccasion/family2.jpg"),
  Facebook: require("../Assets/Icons/Facebook.png"),
  youtube: require("../Assets/Icons/youtube.png"),
  twitter: require("../Assets/Icons/twitter.png"),
  instagram: require("../Assets/Icons/instagram.png"),
  linkedin: require("../Assets/Icons/linkedin.png"),
  traveler: require("../Assets/Icons/traveler.png"),
  traveler1: require("../Assets/Icons/traveler1.png"),
  traveler2: require("../Assets/Icons/traveler2.png"),
  arrowdown: require("../Assets/Icons/arrowdown.png"),
  whitetir: require("../Assets/Icons/whitetir.png"),
  Region: require("../Assets/Icons/Experience/Region.jpg"),
  Region1: require("../Assets/Icons/Experience/new_Region1.png"),
  Region2: require("../Assets/Icons/Experience/Region2.jpg"),
  Region3: require("../Assets/Icons/Experience/Region3.png"),
  groups: require("../Assets/Icons/groups/groups.jpg"),
  groups5: require("../Assets/Icons/groups/groups5.png"),
  groups2: require("../Assets/Icons/groups/groups2.png"),
  home5: require("../Assets/Icons/contact/home1.png"),
  home2: require("../Assets/Icons/contact/home2.png"),
  home3: require("../Assets/Icons/contact/home3.png"),
  home4: require("../Assets/Icons/contact/home4.png"),
  arror_down: require("../Assets/Icons/contact/arror_down.png"),
  Restaurants: require("../Assets/Icons/Restaurants/Restaurants.jpg"),
  Restaurants1: require("../Assets/Icons/Restaurants/Neww_Restaurants1.png"),
  Restaurants2: require("../Assets/Icons/Restaurants/Restaurants2.jpg"),

  Family10: require("../Assets/Icons/Family Friend/Family10.jpg"),
  Family: require("../Assets/Icons/Family Friend/Family3.jpg"),
  Family5: require("../Assets/Icons/Family Friend/Family5.png"),
  Family6: require("../Assets/Icons/Family Friend/Family6.jpg"),
  Family7: require("../Assets/Icons/Family Friend/fiddler11.jpg"),
  Family8: require("../Assets/Icons/Family Friend/Family8.jpg"),
  Family9: require("../Assets/Icons/Family Friend/Family9.png"),
  Birds: require("../Assets/Icons/Family Friend/Birds.png"),
  Weddingsbg: require("../Assets/Icons/Weddings/Weddingsbg.jpg"),
  Weddings: require("../Assets/Icons/Weddings/Weddings.png"),
  Weddings2: require("../Assets/Icons/Weddings/Wedding2.jpg"),
  Weddings3: require("../Assets/Icons/Weddings/Weddings3.png"),
  Weddings4: require("../Assets/Icons/Weddings/Weddings4.jpg"),
  Resort111: require("../Assets/Icons/RESORT/Resort.png"),
  Resort3: require("../Assets/Icons/RESORT/Resort3.jpg"),
  Resort4: require("../Assets/Icons/RESORT/Resort4.jpg"),
  fiddlerchalet: require("../Assets/Icons/texture-chalet.png"),
  Resort55: require("../Assets/Icons/RESORT/Resort5.jpg"),
  Resort6: require("../Assets/Icons/RESORT/Resort6.jpg"),
  Resort7: require("../Assets/Icons/RESORT/Resort7.jpg"),
  Resort9: require("../Assets/Icons/RESORT/Resort9.png"),
  Resort51: require("../Assets/Icons/RESORT/Resort51.png"),
  Resort14: require("../Assets/Icons/RESORT/Resort14.png"),
  Resort16: require("../Assets/Icons/RESORT/Resort16.png"),
  Resort15: require("../Assets/Icons/RESORT/Resort15.png"),
  Resort11: require("../Assets/Icons/RESORT/Resort11.png"),
  Resort12: require("../Assets/Icons/RESORT/Resort13.jpg"),
  Resort13: require("../Assets/Icons/RESORT/Resort13.png"),
  menu: require("../Assets/Icons/menu.png"),
  rabbit7: require("../Assets/Icons/Discover/rabbit7.png"),
  Vector8: require("../Assets/Icons/Vector8.png"),
  Vector9: require("../Assets/Icons/Vector9.png"),
  Vector10: require("../Assets/Icons/Vector10.png"),
  rsevenements: require("../Assets/Icons/rsevenements.png"),
  family_: require("../Assets/Icons/Foranyoccasion/family_.jpg"),
  corpo1: require("../Assets/Images/Winter/Images/corpo1.png"),
  Group: require("../Assets/Icons/Group.png"),

  Rabbit: require("../Assets/imgas/Rabbit.jpg"),
  Bear: require("../Assets/imgas/Bear.jpg"),
  Deer: require("../Assets/imgas/Deer.jpg"),
  Moose: require("../Assets/imgas/Moose.jpg"),
  Elk: require("../Assets/imgas/Elk.jpg"),
  HomeServicesConcierge: require("../Assets/imgas/home/HomeServicesConcierge.jpg"),
  Christmas: require("../Assets/imgas/home/Christmas.jpg"),
  LocalActivities: require("../Assets/Icons/Restaurants/LocalActivities.jpg"),
  resort2Exp: require("../Assets/Icons/Experience/resort2.jpg"),
  resort2: require("../Assets/Icons/Experience/resort2.jpg"),
  resort4: require("../Assets/Icons/Experience/resort4.jpg"),
  Resort555: require("../Assets/Icons/RESORT/Resort555.png"),
  CorporateBanner: require("../Assets/Icons/RESORT/CorporateBanner.jpg"),
  home13: require("../Assets/Icons/home/home3.jpg"),
  Activitiexpe: require("../Assets/Icons/Experience/Activitiexpe.jpg"),
  Activitiexpe1: require("../Assets/Icons/Experience/Activitiexpe1.jpg"),
  Activitiexpe2: require("../Assets/Icons/Experience/Activitiexpe2.jpg"),
  Activitiexpe3: require("../Assets/Icons/Experience/Activitiexpe3.jpeg"),
  Restaurants3: require("../Assets/Icons/Restaurants/Restaurants3.jpg"),

  Union: require("../Assets/Icons/Discover/vs/Union.png"),
  Union1: require("../Assets/Icons/Discover/vs/Union1.png"),
  Union2: require("../Assets/Icons/Discover/vs/Union2.png"),
  Union3: require("../Assets/Icons/Discover/vs/Union3.png"),
  Union4: require("../Assets/Icons/Discover/vs/Union4.png"),
  wedding123: require("../Assets/Icons/Weddings/wedding123.jpg"),
  personalizedevents1: require("../Assets/Icons/Family Friend/personalizedevents1.jpeg"),
  personalizedevents2: require("../Assets/Icons/Family Friend/personalizedevents2.jpeg"),
  personalizedevents3: require("../Assets/Icons/Family Friend/personalizedevents3.jpeg"),
  personalizedevents4: require("../Assets/Icons/Family Friend/personalizedevents4.jpeg"),
  carmode1_: require("../Assets/Icons/vs/carmode1.jpg"),
  map_final: require("../Assets/Icons/vs/map-final.jpg"),
  Gibbys: require("../Assets/imgas/Gibbys.png"),
  Bookyourstay: require("../Assets/imgas/Bookyourstay.jpg"),
  ResortActivities: require("../Assets/imgas/ResortActivities.jpg"),
  Activities1: require("../Assets/imgas/ResortActivities/Activities1.jpg"),
  Activities2: require("../Assets/imgas/ResortActivities/Activities2.jpg"),
  Activities3: require("../Assets/imgas/ResortActivities/Activities3.jpg"),
  ResortServicensBanner: require("../Assets/imgas/ResortServicensBanner.jpg"),
  ServicensConcierge: require("../Assets/imgas/ServicensConcierge.jpg"),
  BabyGear: require("../Assets/imgas/BabyGear.jpg"),
  new_bed: require("../Assets/Icons/Experience/new_bed.jpg"),
  GibbysSauveurPic: require("../Assets/imgas/GibbysSauveurPic.jpg"),
  ChaletsBanner: require("../Assets/imgas/ChaletsBanner.jpg"),
  CorporateGroups: require("../Assets/imgas/CorporateGroups.jpg"),
  Discover44: require("../Assets/imgas/Discover44.jpg"),
  coporet_ic: require("../Assets/imgas/coporet_ic.png"),
  weddings_IC: require("../Assets/imgas/weddings_IC.jpg"),
  GeneralInformation: require("../Assets/imgas/GeneralInformation.jpg"),
  Amenities: require("../Assets/imgas/Amenities.jpg"),
  website: require("../Assets/website.mp4"),
  ic_orange_close: require("../Assets/Icons/ic_orange_close.png"),
  newsletter: require("../Assets/Icons/newsletter.png"),
  chalets_rabbit: require("../Assets/imgas/ChaletsByType/chalets_rabbit.jpg"),
  chalets_bear: require("../Assets/imgas/ChaletsByType/chalets_bear.jpg"),
  chalets_deer: require("../Assets/imgas/ChaletsByType/chalets_deer.jpg"),
  chalets_moose: require("../Assets/imgas/ChaletsByType/chalets_moose.jpg"),
  chalets_elk: require("../Assets/imgas/ChaletsByType/chalets_elk.png"),
  ic_sun: require("../Assets/Icons/ic_sun.png"),
  ic_winter: require("../Assets/Icons/ic_winter.png"),
  ic_people_icon: require("../Assets/Icons/people_icon.png"),
  black_dot: require("../Assets/Icons/black_dot.png"),
  people_icon_resize: require("../Assets/Icons/people_icon_resize.png"),
  ic_ameniti_pool: require("../Assets/Icons/ic_ameniti_pool.png"),

  partner_sommet: require("../Assets/Icons/Partner/partner_sommet.png"),
  partner_strom: require("../Assets/Icons/Partner/partner_strom.png"),



};
export default Icons;
