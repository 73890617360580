import React, { useState, useEffect } from "react";
import Bookfooter from "../../../component/footer/Bookfooter";
import CursorSlider from "../../../component/CustomSlide/chalersslider";
import BookNow from "../../../component/Button/BookNow";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../../component/backgroundtext/resortsbg";
import { useNavigate } from "react-router-dom";
import { useMedia } from "../../../MediaProvider";

function LocalactivitiesDining() {
  const { media } = useMedia();
  const Icons = media.Icons;

  const restaurants = [
    {
      name: "Nearby",
      title: " Sports",
      titlename: [
        {
          titleShow: "Sommet Saint-Sauveur",
          web_link:'https://www.sommets.com/'
        },
        {
          titleShow: "Sommet Morin-Heights",
          web_link:"https://www.sommets.com/en/discover-les-sommets/sommet-morin-heights-winter/"
        },
        {
          titleShow: "Club de Golf Lachute",
        },
      ],
      Image: Icons.Restaurants3,
    },

    {
      name: "Nearby",
      title: "Entertainment",
      titlename: [
        {
          titleShow: "Sommet Saint-Sauveur Water Park",
          web_link:"https://www.sommets.com/en/what-to-do/water-park/",
        },
        {
          titleShow: "Acro-Nature",
        },
        {
          titleShow: "Val-David Summer Market",
        },
      ],
      Image: Icons.Restaurants1,
    },
    {
      name: "Nearby",
      title: "Spas",
      titlename: [
        {
          titleShow: "Strøm Spa Nordique",
          web_link: "https://www.stromspa.com/",
        },
        {
          titleShow: "Ofuro Station ZEN",
        },

        {
          titleShow: "Amerispa Morin-Heights",
        },
      ],
      Image: Icons.Region2,
    },

    {
      name: "Nearby",
      title: "Stores",
      titlename: [
        {
          titleShow: "Au Clair de la Lune Boutique",
        },
        {
          titleShow: "Tanger Factory Outlets",
        },
        {
          titleShow: "Desjardins Marine",
        },
      ],
      Image: Icons.Restaurants,
    },

    {
      name: "Nearby",
      title: "Restaurants",
      titlename: [
        {
          titleShow: "Maestro",
        },
        {
          titleShow: "Gibbys",
        },
        {
          titleShow: "Steak Frites St-Paul",
        },
      ],
      Image: Icons.GibbysSauveurPic,
    },
  ];

  const [responsiveHeight, setResponsiveHeight] = useState(
    window.innerWidth > 768 ? "70vh" : "30vh"
  );
  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerWidth > 768 ? "70vh" : "30vh";
      setResponsiveHeight(newHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLinkClick = () => {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRedirect = (item) => {
    navigate("/discoverchalets");
  };
  const Depending = [
    {
      name: " ",
      height: "100vh",
      bgimg: Icons.LocalActivities,
      Rentals: "Local activities & Dining ",
      width: "1200px",
    },
  ];

  const onClickWebSiteOpen = (item) =>{
    window.open(item.web_link, "_blank", "noopener,noreferrer");
  }
  return (
    <div>
      <section className="ACTIVITIES_data family_fillte">
        <BackgroundImage Depending={Depending} lineRemove={true} />
      </section>
      <section className=" bg-skyYellow md:pt-20 md:pb-20 max-[767px]:py-10">
        <div className="container">
          <p className="text-20 AkagiPro-Black text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "From skiing and golfing to spas and waterparks, the Saint-Sauveur Valley is a bustling destination with a lot to offer to visitors of all ages. For a complete experience, explore the region’s diverse array of restaurants to discover local hidden gems and fine dining favourites!"
                ),
              }}
            />
          </p>
        </div>
      </section>

      <section
        id="service"
        className="md:mb-[370px] max-[767px]:mb-8"
      ></section>
      <section className="container relative px-8   restaurants_h ">
        <div className="flex flex-wrap justify-center  grid-cols-3 gap-4 md:-mt-10 md:-mb-[120px]  max-[767px]:mb-10">
          {restaurants.map((item, index) => (
            <div
              key={index.toString()}
              style={{ width: "32%" }}
              className="Massage md:mb-[300px] max-[767px]:mb-3 md:relative"
            >
              <div className="animation_img md:absolute md:-mt-64">
                <img
                  loading="lazy"
                  src={item.Image}
                  className={
                    item.anform
                      ? `${item.anform}`
                      : "w-full relative  img_animation"
                  }
                  style={{ transform: `${item.transform}` }}
                />
              </div>
              <div
                className="p-7 md:h-full  max-[767px]:pl-12 text-white bg-lightyellow relative z-10  max-[767px]:-mt-20"
                style={{ width: "90%" }}
              >
                <h3 className="AkagiPro-Black text-20  uppercase  ">
                  {" "}
                  {t(item.name)}
                </h3>
                <p className="text-32 lg:-mt-0  max-[767px]:mt-1 md:mt-1 md:mb-1  lg:-mb-0  max-[767px]:mb-1 AkagiPro-Black ">
                  {t(item.title)}
                </p>
                <div className="text-20">
                  {/* <div
                    dangerouslySetInnerHTML={{ __html: t(item.titlename) }}
                  /> */}
                  <ul>
                    {item.titlename.map((item) => (
                      <li
                        style={{
                          cursor: item?.web_link ? "pointer" : "default",
                        }}
                        onClick={()=>item?.web_link ? onClickWebSiteOpen(item) : null}
                      >
                        {t(item.titleShow)}
                      </li>
                    ))}
                  </ul>
                </div>
                {BookNow.map((BookNow, index) => (
                  <Link
                    key={index.toString()}
                    to="/discoverchalets"
                    onClick={handleLinkClick}
                    className="text-white"
                    style={{ display: "none" }}
                  >
                    {" "}
                    <h3 className="AkagiPro-Black md:ml-4 text-20 md:-mb-2 capitalize md:mt-9 max-[767px]:mt-5 flex items-baseline gap-2 cursor-pointer ">
                      {t("view more")}{" "}
                      <img
                        src={Icons.whitetir}
                        loading="lazy"
                        className="max-[767px]:w-6"
                      />
                    </h3>
                  </Link>
                ))}
              </div>
            </div>
          ))}{" "}
        </div>
      </section>
      <section className="text-center md:-mt-52  px-8 max-[767px]:mt-1">
        <div className="container">
          <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">
            {t("Discover our chalets")}
          </h1>
          <p className="text-20 AkagiPro-book md:mt-4">
            {t(
              "Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ."
            )}
          </p>
          <p
            className="text-20 AkagiPro-book md:mt-4"
            dangerouslySetInnerHTML={{
              __html: t(
                "Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool."
              ),
            }}
          />
          <p className="text-20 AkagiPro-book md:mt-4">
            {t(
              "Read each model’s description for specific features and detailed information."
            )}
          </p>
        </div>
      </section>
      <section className="relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ">
        <div>
          <CursorSlider handleRedirect={handleRedirect} />
        </div>
      </section>
      <Bookfooter />
    </div>
  );
}
export default LocalactivitiesDining;
