import React, { createContext, useState, useContext, useEffect } from "react";
import Icons from "./Constants/Icon";
import Images from "./Assets/Images/Images";
const MediaContext = createContext();
export const MediaProvider = ({ children }) => {
  const [media, setMedia] = useState(() => {
    const storedMediaType = localStorage.getItem("mediaType") || "Icons";
    if (storedMediaType === "Icons") {
      return { Icons: Icons };
    } else {
      return { Icons: Images };
    }
  });
  const changeMedia = () => {
    const currentMediaType = localStorage.getItem("mediaType");
    if (currentMediaType == null || currentMediaType === "Icons") {
      localStorage.setItem("mediaType", "Images");
      setMedia({ Icons: Images });
    } else {
      localStorage.setItem("mediaType", "Icons");
      setMedia({ Icons: Icons });
    }
  };
  return (
    <MediaContext.Provider value={{ media, changeMedia }}>
      {children}
    </MediaContext.Provider>
  );
};
export const useMedia = () => {
  return useContext(MediaContext);
};
