const Images = {
  Rabbit: require("../Images/Winter/Images/Rabbit.jpg"),
  Bear: require("../Images/Winter/Images/Bear.jpg"),
  Deer: require("../Images/Winter/Images/Deer.jpg"),
  Moose: require("../Images/Winter/Images/Moose.jpg"),
  Elk: require("../../Assets/Images/Winter/Images/Elk.jpg"),
  Rectangle1: require("../Images/Winter/Images/Rectangle1.jpg"),
  Rectangle4: require("../Images/Winter/Images/Rectangle4.jpg"),
  Gibbys: require("../../Assets/imgas/Gibbys.png"),
  HomeServicesConcierge: require("../Images/Winter/Images/HomeServicesConcierge.png"),
  ResortServicensBanner: require("../Images/Winter/Images/HomeServicesConciergeCrop.jpg"),
  lacfiddler: require("../../Assets/Images/Winter/Images/lacfiddler.jpg"),
  Family10: require("../../Assets/Icons/Family Friend/Family10.jpg"),
  groups2: require("../Images/Winter/Images/groups2.png"),
  CorporateBanner: require("../Images/Winter/Images/CorporateBanner.jpg"),
  wedding123: require("../../Assets/Icons/Weddings/wedding123.jpg"),
  family_: require("../../Assets/Images/Winter/Images/family_.jpg"),
  family1: require("../../Assets/Icons/Foranyoccasion/family1.png"),
  family2: require("../../Assets/Images/Winter/Images/family2.jpg"),
  Christmas: require("../../Assets/imgas/home/Christmas.jpg"),
  evenements1: require("../../Assets/Icons/Foranyoccasion/evenements1.jpg"),
  evenements5: require("../../Assets/Icons/Foranyoccasion/evenements5.jpg"),
  evenements4: require("../../Assets/Icons/Foranyoccasion/evenements4.jpg"),
  evenements3: require("../../Assets/Icons/Foranyoccasion/evenements3.jpg"),
  Resort111: require("../../Assets/Icons/RESORT/Resort.png"),
  Resort3: require("../../Assets/Icons/RESORT/Resort3.jpg"),
  evenements2: require("../Images/Winter/Images/new_evenements2.jpg"),
  Restaurants1: require("../Images/Winter/Images/Neww_Restaurants1.jpg"),
  ChaletsBanner: require("../../Assets/imgas/ChaletsBanner.jpg"),
  Bookyourstay: require("../Images/Winter/Images/Bookyourstay.png"),
  home13: require("../Images/Winter/Images/new_home13.jpg"),
  BabyGear: require("../Images/Winter/Images/BabyGear.png"),
  Restaurants3: require("../Images/Winter/Images/Restaurants3.jpg"),
new_bed: require("../../Assets/Icons/Experience/new_bed.jpg"),
  Activitiexpe: require("../../Assets/Icons/Experience/Activitiexpe.jpg"),
  Activitiexpe1: require("../Images/Winter/Images/Rectangle1.jpg"),
  Activitiexpe2: require("../Images/Winter/Images/Rectangle4.jpg"),
  Activitiexpe3: require("../Images/Winter/Images/Activitiexpe3.png"),
  Region3: require("../Images/Winter/Images/Region3.png"),
  Region2: require("../Images/Winter/Images/Region2.jpg"),
  Region1: require("../Images/Winter/Images/new_Region1.png"),
  Region: require("../Images/Winter/Images/Region.jpg"),
  Vectorcagaru: require("../../Assets/Icons/Vectorcagaru.png"),
  Vectorrish: require("../../Assets/Icons/Vectorrish.png"),
  Vector8: require("../../Assets/Icons/Vector8.png"),
  Vector9: require("../../Assets/Icons/Vector9.png"),
  Vector10: require("../../Assets/Icons/Vector10.png"),
  Facebook: require("../../Assets/Icons/Facebook.png"),
  youtube: require("../../Assets/Icons/youtube.png"),
  twitter: require("../../Assets/Icons/twitter.png"),
  instagram: require("../../Assets/Icons/instagram.png"),
  linkedin: require("../../Assets/Icons/linkedin.png"),
  ResortActivities: require("../Images/Winter/Images/ResortActivities.jpg"),
  Activities1: require("../Images/Winter/Images/Activities1.jpg"),
  Activities3: require("../Images/Winter/Images/Activities3.jpg"),
  resort2Exp: require("../Images/Winter/Images/resort2Exp.jpg"),
  Activities2: require("../Images/Winter/Images/Act_new_2.jpg"),
  resort2: require("../Images/Winter/Images/Activities2.png"),
  ServicensConcierge: require("../../Assets/imgas/ServicensConcierge.jpg"),
  LocalActivities: require("../Images/Winter/Images/LocalActivities.jpg"),
  resort4: require("../../Assets/Icons/Experience/resort4.jpg"),
  Restaurants2: require("../../Assets/Icons/Restaurants/Restaurants2.jpg"),
  Restaurants: require("../Images/Winter/Images/Restaurants.jpg"),
  GibbysSauveurPic: require("../../Assets/imgas/GibbysSauveurPic.jpg"),
  groups: require("../../Assets/Icons/groups/groups.jpg"),
  fiddlerchalet: require("../../Assets/Icons/texture-chalet.png"),
  groups5: require("../../Assets/Icons/groups/groups5.png"),
  Family: require("../../Assets/Icons/Family Friend/Family3.jpg"),
  Birds: require("../../Assets/Icons/Family Friend/Birds.png"),
  personalizedevents1: require("../../Assets/Icons/Family Friend/personalizedevents1.jpeg"),
  personalizedevents2: require("../../Assets/Icons/Family Friend/personalizedevents2.jpeg"),
  personalizedevents3: require("../Images/Winter/Images/personalizedevents3.jpg"),
  personalizedevents4: require("../../Assets/Icons/Family Friend/personalizedevents4.jpeg"),
  Family5: require("../Images/Winter/Images/Family5.jpg"),
  Family6: require("../Images/Winter/Images/Family6.jpg"),
  Family7: require("../Images/Winter/Images/Family7.jpg"),
  Family8: require("../Icons/Family Friend/Family8.jpg"),
  CorporateGroups: require("../../Assets/imgas/CorporateGroups.jpg"),
  wedding123: require("../../Assets/Icons/Weddings/wedding123.jpg"),
  Family9: require("../../Assets/Icons/Family Friend/Family9.png"),
  arror_down: require("../../Assets/Icons/contact/arror_down.png"),
  Resort4: require("../Icons/RESORT/Resort4.jpg"),
  corpo1: require("../Images/Winter/Images/corpo1.png"),
  Resort55: require("../Images/Winter/Images/HomeServicesConciergeCrop.jpg"),
  Resort6: require("../Images/Winter/Images/Resort6.jpg"),
  Resort7: require("../../Assets/Icons/RESORT/Resort7.jpg"),
  coporet_ic: require("../../Assets/imgas/coporet_ic.png"),
  Resort12: require("../Images/Winter/Images/Resort12.jpg"),
  Resort13: require("../Images/Winter/Images/ResortActivities.jpg"),
  Resort11: require("../Images/Winter/Images/Resort11.png"),
  Resort555: require("../../Assets/Icons/RESORT/Resort555.png"),
  Resort15: require("../../Assets/Icons/RESORT/Resort15.png"),
  Resort16: require("../../Assets/Icons/RESORT/Resort16.png"),
  Resort14: require("../../Assets/Icons/RESORT/Resort14.png"),
  Weddingsbg: require("../../Assets/Icons/Weddings/Weddingsbg.jpg"),
  Weddings: require("../../Assets/Icons/Weddings/Weddings.png"),
  weddings_IC: require("../../Assets/imgas/weddings_IC.jpg"),
  Weddings2: require("../../Assets/Icons/Weddings/Wedding2.jpg"),
  Weddings3: require("../../Assets/Icons/Weddings/Weddings3.png"),
  Weddings4: require("../../Assets/Icons/Weddings/Weddings4.jpg"),
  Discover44: require("../Images/Winter/Images/Discover44.jpg"),
  Amenities: require("../Images/Winter/Images/Activitiexpe3.png"),
  home5: require("../../Assets/Icons/contact/home1.png"),
  home2: require("../../Assets/Icons/contact/home2.png"),
  home3: require("../../Assets/Icons/contact/home3.png"),
  home4: require("../../Assets/Icons/contact/home4.png"),
  GeneralInformation: require("../Images/Winter/Images/GeneralInformation.jpg"),
  map_final: require("../../Assets/Icons/vs/map-final.jpg"),
  carmode1_: require("../Images/Winter/Images/carmode1.jpg"),
  rabbit7: require("../../Assets/Icons/Discover/rabbit7.png"),
  Union: require("../../Assets/Icons/Discover/vs/Union.png"),
  Union1: require("../../Assets/Icons/Discover/vs/Union1.png"),
  Union2: require("../../Assets/Icons/Discover/vs/Union2.png"),
  Union3: require("../../Assets/Icons/Discover/vs/Union3.png"),
  Union4: require("../../Assets/Icons/Discover/vs/Union4.png"),
fiddler: require("../../Assets/Icons/fidder.png"),
  fiddler1: require("../../Assets/Icons/fidder1.png"),
  fiddler2: require("../../Assets/Icons/fidder2.png"),
  fiddler3: require("../../Assets/Icons/fidder3.png"),
  fiddler4: require("../../Assets/Icons/fidder4.png"),
  fiddler5: require("../../Assets/Icons/fidder5.png"),
  fiddler6: require("../../Assets/Icons/fidder6.png"),
  fiddler7: require("../../Assets/Icons/fidder7.png"),
  fiddler8: require("../../Assets/Icons/fidder8.png"),
  fiddler9: require("../../Assets/Icons/fidder9.png"),
  fiddler10: require("../../Assets/Icons/fidder10.png"),
  fiddler11: require("../../Assets/Icons/fidder11.png"),
  fiddler12: require("../../Assets/Icons/fidder12.png"),
  fiddler13: require("../../Assets/Icons/fidder13.png"),
  Tira: require("../../Assets/Icons/Tira.png"),
  Line: require("../../Assets/Icons/Line.png"),
  family: require("../../Assets/Icons/Foranyoccasion/family.png"),
  traveler: require("../../Assets/Icons/traveler.png"),
  traveler1: require("../../Assets/Icons/traveler1.png"),
  traveler2: require("../../Assets/Icons/traveler2.png"),
  arrowdown: require("../../Assets/Icons/arrowdown.png"),
  whitetir: require("../../Assets/Icons/whitetir.png"),
  Resort9: require("../../Assets/Icons/RESORT/Resort9.png"),
  Resort51: require("../../Assets/Icons/RESORT/Resort51.png"),
  menu: require("../../Assets/Icons/menu.png"),
  rsevenements: require("../../Assets/Icons/rsevenements.png"),
  Group: require("../../Assets/Icons/Group.png"),
  website:require("../Images/Winter/Images/website.mp4"),
  ic_orange_close:require("../../Assets/Icons/ic_orange_close.png"),
  newsletter:require("../../Assets/Icons/newsletter.png"),
  ic_sun: require("../../Assets/Icons/ic_sun.png"),
  ic_winter: require("../../Assets/Icons/ic_winter.png"),

  chalets_rabbit: require("../Images/Winter/Images/chalets_rabbit.jpg"),
  chalets_moose: require("../Images/Winter/Images/chalets_moose.jpg"),
  chalets_bear: require("../Images/Winter/Images/chalets_bear.jpg"),

  chalets_deer: require("../Images/Winter/Images/chalets_deer.jpg"),

  chalets_elk: require("../../Assets/Images/Winter/Images/chalets_elk.jpg"),
  ic_people_icon:require("../../Assets/Icons/people_icon.png"),
  black_dot:require("../../Assets/Icons/black_dot.png"),
  people_icon_resize:require("../../Assets/Icons/people_icon_resize.png"),
  ic_ameniti_pool:require("../../Assets/Icons/ic_ameniti_pool.png"),



  partner_sommet: require("../../Assets/Icons/Partner/partner_sommet.png"),
  partner_strom: require("../../Assets/Icons/Partner/partner_strom.png"),
};
export default Images;
