import React from "react";
import fiddlerchalet from '../../../Assets/Icons/texture-chalet.png';
import Bookfooter from '../../../component/footer/Bookfooter';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Drinkingbg from "../../../component/backgroundtext/resortsbg";
import { useMedia } from "../../../MediaProvider";
function Resortservices() {


     const { media } = useMedia();
            const Icons = media.Icons

    const sectionimg = {
        backgroundImage: 'url(' + fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://reservations.fiddlerlakeresort.com/",
        fr: "https://reservations.fiddlerlakeresort.com/fr/"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;
    const handleLinkClick = () => {

    };

    const Depending = [{
        name: " ",
        height: "100vh",
        bgimg: Icons.ResortServicensBanner,
        Rentals: "Resort Services",
        width: "1200px",


    }]
    return (
        <div>
            <div className='family_fillte'>
                <Drinkingbg lineRemove={true} Depending={Depending} />
            </div>
            <section className=" bg-skyYellow md:pt-20 md:pb-20 max-[767px]:py-10 mb-16" >
                <div className="container">
                    <p className="text-20 AkagiPro-Black text-center"><div dangerouslySetInnerHTML={{ __html: t("Whether you require assistance in planning activities or dining during your stay, preparing for a corporate event or renting baby gear for a family getaway, our team is dedicated to making your Fiddler Lake Resort experience seamless and stress-free!") }} /></p>
                </div>
            </section>
            <section className='w-full bg-skyYellow md:py-16 max-[767px]:py-8 BabyGear' style={sectionimg} >
                <div className='container  text-center grid lg:grid-cols-2 gap-6' >
                    <div className="animation_img">
                        <img loading="lazy" src={Icons.ServicensConcierge} className="h-full object-cover img_animation" />
                    </div>
                    <div>
                        <div className="flex justify-center w-full  -mb-1">
                            <div className='flex items-center md:gap-8 max-[767px]:gap-2 line-des1'>
                                <div className='md:w-16 max-[767px]:w-5  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                <h3 className="AkagiPro-Black text-30 "  >{t("CONCIERGE")}</h3>
                            </div>
                        </div>
                        <h1 className="text-black  text-64 Alfarn " style={{ lineHeight: "60px" }}>{t("Services")}</h1>
                        <p className="text-center text-black text-20 AkagiPro-Black mb-0 mt-3">{t("Comprehensive services to enhance your stay:")}</p>
                        <div className="flex justify-center mt-9">
                            <div className="flex md:gap-7 max-[767px]:gap-5  grid-cols-3 md:mt-2  text-left   text-20 AkagiPro-Black md:w-[662px] lg:w-[900px]">
                                <div style={{ width: "50%" }}>
                                    <ul className="grid gap-3 max-[767px]:pl-5" style={{ listStyle: "revert" }}>
                                        <li>{t("24/7 customer service")}</li>
                                        <li>{t("Restaurant and activity reservations")}</li>
                                        <li>{t("On-site convenience store and gift shop")}</li>
                                        <li>{t("Wedding and other event planning")}</li>
                                        <li>{t("Corporate event planning")}</li>
                                    </ul>
                                </div>
                                <div className="bg-black h-full relative before:absolute before:bg-black 
                            before:w-1
                            before:rounded-full
                            before:h-1
                            before:-ml-[2px]
                            before:absolute
                            before:top-0
                            after:w-1 
                            after:rounded-full
                            after:h-1
                            after:-ml-[2px]
                            after:absolute
                            after:bottom-0
                            after:bg-black
                            " style={{ width: "1px" }}>

                                </div>
                                <div style={{ width: "50%" }}>
                                    <ul className="grid gap-3 max-[767px]:pl-4" style={{ listStyle: "revert" }}>
                                        <li >{t("Grocery delivery service")}</li>
                                        <li>{t("Catering service")}</li>
                                        <li>{t("Private chef")}</li>
                                        <li>{t("Car and limousine rental")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='w-full bg-lightyellow md:py-16 max-[767px]:py-8  BabyGear' style={sectionimg}>
                <div className='container  text-center text-white'>
                    <div className="flex justify-center w-full  -mb-1">
                        <div className='flex items-center  md:gap-8 max-[767px]:gap-2 line-des1'>
                            <div className='md:w-16 max-[767px]:w-5  bg-white  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                            <h3 className="AkagiPro-Black text-30 ">{t("EQUIPMENT RENTALS")}</h3>
                        </div>
                    </div>
                    <h1 className="text-white  text-64 Alfarn" style={{ lineHeight: "60px" }}>{t("Audiovisual")}</h1>
                    <p className="AkagiPro-Black text-20 mt-3">{t("We have all the equipment you need on site for your business event :")}</p>
                    < div className="flex justify-center mt-9" >
                        <div className="flex md:gap-12 max-[767px]:gap-5 grid-cols-3  text-left   text-20 AkagiPro-Black ">
                            <div className="max-[767px]:w-[45%]">
                                <ul className="grid gap-3 max-[767px]:pl-4" style={{ listStyle: "revert" }}>
                                    <li >{t("Projector screens")}</li>
                                    <li>{t("Projectors")}</li>
                                    <li>{t("Extra tables and chairs")}</li>
                                </ul>
                            </div>
                            <div className="bg-white h-full relative before:absolute before:bg-white 
                            before:w-1
                            before:rounded-full
                            before:h-1
                            before:-ml-[2px]
                            before:absolute
                            before:top-0
                            after:w-1 
                            after:rounded-full
                            after:h-1
                            after:-ml-[2px]
                            after:absolute
                            after:bottom-0
                            after:bg-white
                            " style={{ width: "1px" }}>
                            </div>
                            <div className="max-[767px]:w-[45%]">
                                <ul className="grid gap-3 max-[767px]:pl-4" style={{ listStyle: "revert" }}>
                                    <li >{t("Karaoke equipment")}</li>
                                    <li>{t("Tableware, glassware, and tablecloths")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='w-full bg-skyYellow md:py-16 md:pb-12 max-[767px]:py-8 BabyGear' style={sectionimg}>
                <div className='container  text-center  grid lg:grid-cols-2 gap-6'>
                    <div className="animation_img">
                        <img loading="lazy" src={Icons.new_bed} className="h-full object-cover img_animation" />
                    </div>
                    <div>
                        <div className="flex justify-center w-full  -mb-1">
                            <div className='flex items-center  md:gap-8 max-[767px]:gap-2 line-des1'>
                                <div className='md:w-16 max-[767px]:w-5 bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                <h3 className="AkagiPro-Black text-30 ">{t("EQUIPMENT RENTALS")}</h3>
                            </div>
                        </div>
                        <h1 className="text-black  text-64 Alfarn" style={{ lineHeight: "60px" }}>{t("Baby gear")}</h1>
                        <p className="text-20 AkagiPro-Black max-[767px]:mt-4 md:mt-8 md:max-w-[850px] md:mx-auto">{t("A selection of baby/toddler safety equipment installed and delivered for your convenience is available upon request, including:")}</p>
                        <div className="flex justify-center mt-6">
                            <div className="flex gap-7 grid-cols-3  text-left   text-20 AkagiPro-Black">
                                <div>
                                    <ul className="grid gap-3" style={{ listStyle: "revert" }}>
                                        <li>{t("Highchairs")}</li>
                                        <li>{t("Playpens")}</li>
                                        <li>{t("Safety gates for stairs")}</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <Link to={currentUrl}>
                <div className="button_yellow text-white  bg-lightyellow md:py-12 max-[767px]:py-5 max-[767px]:my-7  max-[767px]:mt-16 md:my-12 cursor-pointer" style={sectionimg}>
                    <div className="container">
                        <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                    </div>

                </div></Link>
            <section className='md:mt-16'>
                <Bookfooter />

            </section>
        </div >
    )
}
export default Resortservices;
