import React, { useState, useEffect } from 'react';
import GroupsEvents from "../../../../component/Groups & Events/GroupsEvents";
import Drinkingbg from "../../../../component/backgroundtext/resortsbg"
import Bookfooter from "../../../../component/footer/Bookfooter";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContactForm from '../../../../component/contactus/Contact';
import { useMedia } from '../../../../MediaProvider';

function Weddings() {

    
    const { media } = useMedia();
      const Icons = media.Icons;

      const Corporategroups = [{
        Wedding: "Family & Friends ",
        Corporate: "Corporate Groups",
        fabulous: "Host your next business meeting, training session, or team building activity in a fabulous destination in the Laurentians. Our chalets offer an ideal environment that fosters team spirit, creativity, and collaboration.",
        Birds: "Learn more",
        Romantic: "Experience an unforgettable family reunion, birthday, anniversary or holiday celebration at Fiddler Lake Resort. We offer the ultimate venue for any group event, with or without children.",
        img: Icons.CorporateGroups,
        imgs: Icons.Family10,
        capitalize: "capitalize",
        Link: "/family&friends",
        Corporatename: "",
    
    }]
    
    const contactdata = [{
        name: "Wedding planning",
        planning: "Start planning your dream wedding today. Contact us today at",
        complete: "or complete the contact form below. See you soon!"
    }]

    const [ResponsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "70vh" : "30vh"
    );
    const [Responsivewidth, setResponsivewidth] = useState(
        window.innerWidth > 768 ? "46%" : "100%"
    );
    const [Responsivep, setResponsivep] = useState(
        window.innerWidth > 768 ? "950px" : "0px"
    );

    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "70vh" : "30vh";
            const newwidth = window.innerWidth > 768 ? "46%" : "100%";
            const newp = window.innerWidth > 768 ? "30px" : "0px";
            setResponsiveHeight(newHeight);
            setResponsivewidth(newwidth);
            setResponsivep(newp);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const restaurants = [
        {
            title: "An Idyllic Setting for your Ceremony",
            titlename: "Exchange your vows with a backdrop of rustic elegance and nature beauty. Capture the moment amid Fiddler Lake Resort’s secluded pathways and trails winding through lush foliage and wooded areas. Your photo shoot with a professional photographer is sure to leave a lasting impression on you and your guests.",
            Image: Icons.Weddings4,
            chaletsmodels: "Set the stage for your ceremony",
            width: "100%",
            padding: Responsivep,
            paddingRight: "950px",
            Link: "",
            capitalize: "none"

        },
        {
            title: "Luxurious Chalets",
            titlename: "Our exquisite log chalets with majestic windows overlooking lush foliage and scenic views can accommodate 6-18 people.",
            Image: Icons.Discover44,
            chaletsmodels: "Discover our chalets",
            width: Responsivewidth,
            Link: "/discoverchalets",
            capitalize: "none"
        },
        {
            title: "On-Site Activities",
            titlename: "Offer your guests access to exclusive on-site activities including, canoeing, kayaking, tennis, badminton, pickleball, basketball, hiking, indoor/outdoor heated pools, pool table, playground area for children, and much more.",
            Image: Icons.Amenities,
            chaletsmodels: "discover the fiddler lake experience",
            width: Responsivewidth,
            Link: "/experience",
            capitalize: "none"


        },

    ]

    const Depending = [{
        Rentals: "WEDDINGS",
        bgimg: Icons.Weddingsbg,
        height: "100vh",


    }]
    const sectionimg = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const handlesLinkClick = () => {
        const div = document.getElementById("contant");
        if (div) {
            window.scrollTo({
                top: div.offsetTop - 100,
                behavior: 'smooth'
            })
        }
    }
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://reservations.fiddlerlakeresort.com/",
        fr: "https://reservations.fiddlerlakeresort.com/fr/"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;
    return (
        <div className="family_fillte">
            <Drinkingbg lineRemove={true} Depending={Depending} />
            <section className='bg-skyYellow flex items-center  justify-center relative md:mt-20 max-[767px]:mt-10 ' >
                <div className='container px-8 '>
                    <div className='grid grid-cols-2 max-[352px]:grid-cols-1 md:gap-14 max-[767px]:gap-5 h-full'>
                        <div className='flex justify-left items-center max-[767px]:py-5 md:py-12' >
                            <div >
                                <h1 className=" text-64 Alfarn " style={{ textTransform: "none" }}>{t("A Wedding Reception Tailored to Perfection")}</h1>
                                <p className="text-20 text-justify AkagiPro-book md:mt-4">
                                    {t("Create a magical wedding that will leave your guests speechless. Fiddler Lake Resort offers a venue with rustic elegance for weddings. Many options are available to you to help make your special day truly unforgettable!")}
                                </p>
                                <button onClick={handlesLinkClick} className=' book_button  AkagiPro-Black text-white  md:mt-6 max-[767px]:mt-3 px-4 mt-6 uppercase'  > {t(" Start planning your dream wedding now")}</button>

                            </div>
                        </div>
                        <div className='relative md:-bottom-3'>
                            <div className='grid grid-cols-2 md:gap-3 max-[767px]:gap-1 max-[767px]:h-full h-full max-[352px]:h-[247px] '>
                                <div className='bg-black relative  max-[767px]:h-[100%] h-full p-2 md:-mt-12 max-[767px]:-mt-4  animation_img'>
                                    <img loading="lazy" className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Weddings} />

                                </div>
                                <div className='bg-black relative h-full  p-2 max-[767px]:h-[100%] md:-mt-1 max-[767px]:-bottom-2 animation_img'>
                                    <img loading="lazy" className='absolute left-0 top-0 w-full h-full object-cover img_animation ' style={{ overflowClipMargin: "unset" }} src={Icons.weddings_IC} />

                                </div>
                                <div className='bg-black relative h-full  p-2 max-[767px]:h-[100%] md:-mt-11 max-[767px]:-mt-3 animation_img'>
                                    <img loading="lazy" className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Weddings2} />

                                </div>
                                <div className='bg-black relative h-full  max-[767px]:h-[100%] p-2 md:-mb-5 max-[767px]:-bottom-3 animation_img'>
                                    <img loading="lazy" className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Weddings3} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container relative px-8 '>
                <div className=" -my-12 md:-mt-8 max-[767px]:-mt-4">
                    <div className='flex  grid-cols-2 justify-between flex-wrap gap-16 mt-24 mb-20  md:p-10 md:pr-10 pr-8 md:pl-5'>
                        {restaurants.map((item, index) => (
                            <div key={index.toString()} className='bg-lightyellow relative pb-16 ' style={{ width: `${item.width}`, paddingLeft: `${item.padding}` }}>
                                <div className='relative -top-7 -right-7 animation_img'>
                                    <img loading="lazy" src={item.Image} className='w-full  object-cover img_animation' style={{ height: `${item.height}` }} /></div>
                                <div className='px-7 text-white' style={{ maxWidth: `${item.paddingRight}` }}>
                                    <h3 className="AkagiPro-Black text-20 capitalize " > {t(item.name)}</h3>
                                    <p className='text-48 -mt-3 mb-2 AkagiPro-Black capitalize' style={{ lineHeight: "47px", textTransform: `${item.capitalize}` }}>{t(item.title)}</p>
                                    <p className='text-20 AkagiPro-book'>
                                        {t(item.titlename)}</p>
                                    {item.chaletsmodels ? (
                                        <div>
                                            {item.chaletsmodels === "Set the stage for your ceremony" ? (
                                                <h3 onClick={handlesLinkClick} className="AkagiPro-Black text-20 uppercase flex items-baseline gap-2 absolute bottom-5 cursor-pointer pr-5">
                                                    {t(item.chaletsmodels)} <img loading="lazy" className='max-[767px]:w-[31px]' src={Icons.whitetir} /></h3>

                                            ) : (
                                                <Link to={item.Link} className='text-white' >
                                                    <h3 onClick={handlesLinkClick} className="AkagiPro-Black text-20 uppercase flex items-baseline gap-2 absolute bottom-5 cursor-pointer pr-5">
                                                        {t(item.chaletsmodels)} <img loading="lazy" className='max-[767px]:w-[31px]' src={Icons.whitetir} /></h3>
                                                </Link>
                                            )}
                                        </div>
                                    ) : (<h3 style={{ display: "none" }}></h3>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <GroupsEvents Corporategroups={Corporategroups} />
            <Link to={currentUrl}>
                <div className="bg-lightyellow  button_yellow  text-white py-12 max-[767px]:my-7 md:my-12 " style={sectionimg}>
                    <div className="container">
                        <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                    </div>
                </div>
            </Link>
            <div className='md:mb-14 max-[767px]:mb-7' id='contant' >
                <ContactForm  contactdatanew={contactdata} />
            </div>
            <Bookfooter />
        </div>
    )
}
export default Weddings;