import React, { useState, useEffect } from "react";
import "./chalets.css";
// import Icons from '../../../Constants/Icon';
import fiddlerchalet from "../../../Assets/Icons/texture-chalet.png";
import CursorSlider from "../../../component/CustomSlide/chalersslider";
import Corporateslider from "../../../component/CustomSlide/Corporateslider";
import Bookfooter from "../../../component/footer/Bookfooter";
import BackgroundImage from "../../../component/CustomSlide/hompeslider";
import { Link } from "react-router-dom";
import BookNow from "../../../component/Button/BookNow";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Amenities from "../../../component/Amenities/Amenities";
import { HashLink } from "react-router-hash-link";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import { setRedirect } from "../../../Config/Actions/redirectchalets";
import { useMedia } from "../../../MediaProvider";
import Marquee from "react-fast-marquee";

function Home() {
  const { media } = useMedia();
  const Icons = media.Icons;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLanguageCode = i18n.language;
  const urls = {
    en: "https://reservations.fiddlerlakeresort.com/",
    fr: "https://reservations.fiddlerlakeresort.com/?lang=fr",
  };
  const currentUrl = urls[currentLanguageCode] || urls.en;

  const landingImage = [
    {
      image: Icons.partner_sommet,
      link: "https://www.sommets.com/",
    },
    {
      image: Icons.partner_strom,
      link: "https://www.stromspa.com/",
    },
  ];

  const onClickLinkOpen = (item) => {
    window.open(item.link, "_blank");
  };

  const restaurants = [
    {
      name: "activities",
      title: "on site",
      titlename:
        "Take advantage of our wide range of indoor and outdoor on-site activities, reserved exclusively for our guests.",
      Image: Icons.Rectangle1,
      Link: "/resortactivities",
    },
    {
      name: "activities",
      title: "in the region",
      titlename:
        "The Saint-Sauveur Valley is bustling with activities and fun for guests of all ages.",
      Image: Icons.Rectangle4,
      Link: "/localactivitiesdining",
    },
    {
      name: "restaurants",
      title: "nearby",
      titlename:
        "Treat yourself to a night out at one of the many restaurants within 15 minutes of Fiddler Lake Resort.",
      Image: Icons.Gibbys,
      Link: "/localactivitiesdining",
    },
    {
      name: "SERVICES",
      title: "concierge",
      titlename:
        "Our dedicated team ensures that all your needs and special requests are taken care of throughout your stay with us.",
      Image: Icons.HomeServicesConcierge,
      Link: "/resortservices",
    },
  ];
  const rabbitslider = [
    {
      img: Icons.Family10,
      link: "/family&friends",
      name: "GROUPS & EVENTS",
      titlename: "family & friends",
      title: "For family celebrations or get-togethers with friends",
      className: "py-12",
    },
    {
      img: Icons.CorporateBanner,
      name: "GROUPS & EVENTS",
      titlename: "Corporate events",
      title:
        "For business meetings, training sessions, or team building activities",
      link: "/corporate",
      className: "py-12",
    },
    {
      img: Icons.wedding123,
      name: "GROUPS & EVENTS",
      titlename: "Weddings",
      link: "/weddings",
      title: "For couples seeking an unforgettably romantic experience",
      className: "py-12",
    },
  ];
  const rabbitsliderf = [
    {
      img: Icons.family_,
      link: "/discoverchalets",
      names: "Nature",
      titlename: "Explore",
      title:
        "Explore the forest by hiking and snowshoeing in the resort trails. Breathe the fresh air of the Laurentians as you take a tranquil canoe or kayak ride or dive into Fiddler Lake for a fresh swim.",
    },
    {
      img: Icons.family1,
      link: "/discoverchalets",
      names: "In the Moment",
      titlename: "Delight",
      title:
        "Embrace a getaway filled with shared laughter, mutual smiles and lasting memories! Savour simple pleasures while you unwind and recharge.",
    },
    {
      img: Icons.family2,
      link: "/discoverchalets",
      names: "In luxury",
      titlename: "INDULGE",
      title:
        "Enjoy an unforgettable stay  at a private resort featuring luxurious log homes nestled next to Fiddler Lake. With an abundance of sunlight streaming through majestic windows, you’ll be dazzled by the spectacular views of natural landscapes.",
    },
  ];

  const handleLinkClick = () => {
    dispatch(setLoadingTrue());
    dispatch(setRedirect("1"));
    setTimeout(() => {
      dispatch(setLoadingFalse());
    }, 900);
  };

  const [responsiveHeight, setResponsiveHeight] = useState(
    window.innerWidth > 768 ? "100vh" : "30vh"
  );
  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerWidth > 768 ? "100vh" : "30vh";
      setResponsiveHeight(newHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sectionimg = {
    backgroundImage: "url(" + fiddlerchalet + ")",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    minheight: "882px",
  };
  const Sauveur = [
    {
      bgimg: Icons.website,
      name: "FIDDLER LAKE RESORT",
      button: "book now",
      height: responsiveHeight,
      className: "gooddes",
      Rentals: "THE ULTIMATE CHALET GETAWAY",
    },
    {
      bgimg: Icons.family,
    },
    {
      bgimg: Icons.family,
    },
  ];
  const navigate = useNavigate();
  const handleRedirect = (item) => {
    navigate("/discoverchalets");
  };
  return (
    <div>
      <div className="slider_data uppercase font-bold">
        <BackgroundImage Sauveur={Sauveur} />
      </div>
      <section className="GROUPS_EVENTS  w-full overflow-hidden md:mt-20 max-[767px]:mt-5  max-[767px]:-mb-2 md:mb-20">
        <div className="md:ml-14 max-[767px]:ml-0  w-full Corporateslide_s">
          <Corporateslider rabbitsliderd={rabbitsliderf} />
        </div>
      </section>
      <section className="text-center container  px-8">
        <div className="flex justify-center w-full max-[767px]:mt-4  md:mt-14 -mb-6 "></div>
        <h1 className="text-black  mt-3  text-64 Alfarn  md:mt-2 capitalize">
          {t("Discover our chalets")}
        </h1>
        <p className="text-20 AkagiPro-book md:mt-4">
          {t(
            "Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ."
          )}
        </p>
        <p
          className="text-20 AkagiPro-book md:mt-4"
          dangerouslySetInnerHTML={{
            __html: t(
              "Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool."
            ),
          }}
        />
        <p className="text-20 AkagiPro-book md:mt-4">
          {t(
            "Read each model’s description for specific features and detailed information."
          )}
        </p>
      </section>
      <section className="relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ">
        <div>
          <CursorSlider handleRedirect={handleRedirect} />
        </div>
      </section>
      <section
        className="w-full bg-skyYellow pt-10 pb-14 my-16  md:my-16 md:mt-5 max-[767px]:-mt-4  max-[767px]:my-8 Amenities_h"
        style={sectionimg}
      >
        <Amenities />
      </section>
      <section className="bg-skyYellow flex items-center justify-center relative md:mb-[120px] max-[767px]:mb-20 md:mt-[85px]  ">
        <div className="container  px-8">
          <div className="grid grid-cols-2 gap-8 justify-center items-center relative max-[600px]:flex flex-col-reverse flex-wrap">
            <div className=" md:py-0 py-3">
              <h3 className="Akagi-Pro-blacks text-32 md:max-w-[400px]">
                {t("LET NATURE INSPIRE YOUR NEXT CORPORATE RETREAT")}
              </h3>
              <p className="text-20 AkagiPro-book md:mt-5 md:max-w-[560px]">
                {t(
                  "Host your next business event at Fiddler Lake Resort! With so many team building activities to do on-site, your team will come out more collaborative, recharged, and committed than ever."
                )}
              </p>
              <Link to="./corporate" onClick={handleLinkClick}>
                {" "}
                <button
                  style={{ fontSize: "18px" }}
                  className=" md:w-52 w-24 md:mt-5 max-[767px]:mt-3 book_button uppercase AkagiPro-Black text-white box_show"
                >
                  {t("Find Out More")}
                </button>
              </Link>
            </div>
            <div className="relative h-full md:min-h-[450px] min-h-[220px]  Find_Out_More team_will">
              <div className="team_will absolute md:-bottom-7 -bottom-5 object-cover w-full h-[220px] md:min-h-[450px] Find_Out_More animation_img max-[600px]:relative">
                <img
                  loading="lazy"
                  className=" object-cover w-full h-full img_animation"
                  src={Icons.lacfiddler}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container relative px-8  ">
        <div className="grid  md:grid-cols-2 grid-cols-1 gap-12 md:p-10  max-[767px]:pr-9 max-[767px]:mt-24  md:mt-0">
          {restaurants.map((item, index) => (
            <div
              key={index.toString()}
              className="bg-lightyellow relative  pb-16 md:mb-12"
            >
              <div className="w-full  relative -top-7 -right-7 animation_img">
                <img
                  loading="lazy"
                  style={{}}
                  src={item.Image}
                  className="w-full h-full img_animation  relative "
                />
              </div>
              <div className="px-7 text-white">
                <h3 className="AkagiPro-Black   text-20 uppercase ">
                  {" "}
                  {t(item.name)}
                </h3>
                <p className="text-48  max-[767px]:mt-0 lg:mt-0 md:mt-2 mb-2 max-[767px]:mb-3 AkagiPro-Black book_capital">
                  {t(item.title)}
                </p>
                <p className="text-20 AkagiPro-book md:mb-7 md:max-w-[480px]">
                  {t(item.titlename)}
                </p>
                <Link
                  className="text-white"
                  to={item.Link}
                  onClick={handleLinkClick}
                >
                  {" "}
                  <h3 className="AkagiPro-Black text-20  flex items-baseline gap-3 cursor-pointer absolute bottom-6 uppercase">
                    {" "}
                    {t("Discover more")}{" "}
                    <img
                      loading="lazy"
                      src={Icons.whitetir}
                      className="max-[767px]:w-[32px]"
                    />
                  </h3>
                </Link>
              </div>
            </div>
          ))}{" "}
        </div>
      </section>

      <section className="Corporateslide_s GROUPS_EVENTS  all_groups  w-full overflow-hidden md:mt-20  max-[767px]:mt-7  max-[767px]:-mb-2 -mb-5">
        <div className="md:ml-14 max-[767px]:ml-0  w-full">
          <Corporateslider rabbitsliderd={rabbitslider} />
        </div>
      </section>
      <section className="bg-skyYellow flex items-center justify-center relative md:mt-[195px] max-[767px]:mt-5 max-[767px]:-mb-5   md:mb-32 h-[173px] max-[374px]:h-[280px]  md:h-[539px] occasion_for1">
        <div className="container px-8">
          <div className="grid grid-cols-2  max-[767px]:flex  max-[374px]:flex-wrap ">
            <div className="flex justify-center items-center  max-[767px]:w-1/2 max-[374px]:mb-6 occasion_ ">
              <div>
                <div className="flex items-center md:gap-8 max-[767px]:gap-2 line-des1">
                  <div
                    className="md:w-16 w-6  bg-black  items-center mb-2 line-des"
                    style={{ height: "1px" }}
                  ></div>
                  <h3 className="Akagi-Pro-blacks  text-32 ">
                    {t("CHALET RENTALS")}
                  </h3>
                </div>
                <h1 className=" text-64 Alfarn" style={{ lineHeight: "0.9" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("For any <br /> occasion"),
                    }}
                  />
                </h1>
              </div>
            </div>
            <div className="md:w-full w-full max-[767px]:flex max-[767px]:justify-center occasion_for">
              <div className="grid grid-cols-3 gap-3  -mb-8 last_rentals">
                <div className="bg-black relative md:h-[280px] md:mt-6 max-[767px]:h-[77px]  max-[767px]:w-[76px] p-2 hiddened">
                  <img
                    loading="lazy"
                    className="absolute left-0 top-0 w-full h-full object-cover object-top"
                    src={Icons.Christmas}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black ">
                        {t("Christmas")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok uppercase AkagiPro-Black text-white">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-black relative   md:h-[280px] md:mx-2 max-[767px]:h-[77px]  max-[767px]:w-[76px] p-2  max-[767px]:-mt-2 md:-mt-14 hiddened">
                  <img
                    loading="lazy"
                    className="w-full h-full absolute left-0 top-0 object-cover"
                    src={Icons.evenements1}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black ">
                        {t("Spring Break")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok  uppercase AkagiPro-Black text-white ">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-black p-2 relative md:h-[280px] md:mt-6 max-[767px]:h-[77px]  max-[767px]:w-[76px]  hiddened">
                  <img
                    className="w-full h-full absolute left-0 top-0 object-cover"
                    src={Icons.evenements2}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black ">
                        {t("Mother’s Day")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok  uppercase AkagiPro-Black text-white">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-black relative p-2   md:h-72 max-[767px]:h-[77px]  max-[767px]:w-[76px]  hiddened">
                  <img
                    loading="lazy"
                    className="w-full h-full absolute left-0 top-0 object-cover"
                    src={Icons.evenements5}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black ">
                        {t("New Year’s Eve")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok  uppercase AkagiPro-Black text-white">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-black relative  md:h-[280px]  max-[767px]:h-[77px]  max-[767px]:w-[76px]  p-2 max-[767px]:-mt-2 md:-mt-[80px] hiddened">
                  <img
                    loading="lazy"
                    className="w-full absolute left-0 top-0 h-full object-cover"
                    src={Icons.evenements4}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black capitalize">
                        {t("easter")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok  uppercase AkagiPro-Black text-white">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-black p-2 relative  md:h-72 max-[767px]:h-[77px]  max-[767px]:w-[76px] hiddened">
                  <img
                    loading="lazy"
                    className="w-full absolute left-0 top-0 h-full object-cover"
                    src={Icons.evenements3}
                  />
                  <div className="h-full relative w-full md:border max-[767px]:border border-lightyellow hidden justify-center items-center hiddenead">
                    <div className="text-center ">
                      <h2 className="  text-white md:text-4xl md:mb-2 max-[767px]:mb-0 text-sm AkagiPro-Black ">
                        {t("Canada Day")}
                      </h2>
                      {BookNow.map((BookNow, index) => (
                        <Link key={index.toString()} to={currentUrl}>
                          {" "}
                          <button className="md:w-32 w-12 book_button button_bok  uppercase AkagiPro-Black text-white">
                            {t("book now").slice(0, 8)}
                          </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Link to={currentUrl}>
        <div
          className="button_yellow text-white  bg-lightyellow md:py-12 max-[767px]:py-5 max-[767px]:my-7  max-[767px]:mt-16 md:my-12 cursor-pointer"
          style={sectionimg}
        >
          <div className="container">
            <div className="text-48 text-center  Alfarn">
              {t("PLAN YOUR GETAWAY NOW")}
            </div>
          </div>
        </div>
      </Link>
      <section className="bg-skyYellow  md:-mt-2 py-7  max-[767px]:mt-10">
        <div className="container">
          <div className="text-center">
            <h1 className="text-48 Alfarn">{t("Careers")}</h1>
            <p className="md:mt-7 text-20 AkagiPro-Black">
              {t(
                "Become a member of the Fiddler Lake Resort team!  Enjoy working in an idyllic setting in the heart of the Laurentians."
              )}
            </p>
            <div className="flex justify-center">
              <HashLink to="/contactus" onClick={handleLinkClick}>
                {" "}
                <button
                  style={{ fontSize: "18px" }}
                  className=" md:w-52 w-24 md:mt-7 max-[767px]:mt-3 book_button  AkagiPro-Black text-white"
                >
                  {t("FIND OUT MORE")}
                </button>
              </HashLink>
            </div>
          </div>
        </div>
      </section>

      <section style={{}} className="bg-white  md:mt-9   max-[767px]:mt-10">
        <div className="container">
          <div className="text-center">
            <h1
              style={{ padding: 0, margin: 0, fontWeight: "normal" }}
              className="text-20 AkagiPro-Black"
            >
              {t("Partners")}
            </h1>
          </div>
        </div>
      </section>
      <div style={{display:"flex",justifyContent:"center"}}  className="mt-3 mb-4">
        {landingImage.map((item, index) => (
          <div
            key={index}
            className="marque_container"
            onClick={() => onClickLinkOpen(item)}
          >
            <img
              src={item.image}
              alt={`Carousel ${index}`}
              className="carousel-image"
            />
          </div>
        ))}
      </div>
      {/* <Marquee speed={10} className="mt-3" pauseOnHover autoFill={true}>
          {landingImage.map((item, index) => (
            <div
              key={index}
              className="marque_container"
              onClick={() => onClickLinkOpen(item)}
            >
              <img
                src={item.image}
                alt={`Carousel ${index}`}
                className="carousel-image"
              />
            </div>
          ))}
        </Marquee> */}
      {/* <Marquee
        speed={10}
        direction="right"
        className="mt-3 mb-4"
        pauseOnHover
        autoFill={true}
      >
        {landingImage.map((item, index) => (
          <div
            key={index}
            className="marque_container"
            onClick={() => onClickLinkOpen(item)}
          >
            <img
              src={item.image}
              alt={`Carousel ${index}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Marquee> */}

      <section className="md:mt-16">
        <Bookfooter />
      </section>
    </div>
  );
}

export default Home;
