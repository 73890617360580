import React, { useState, useEffect } from 'react';
import Bookfooter from "../../../component/footer/Bookfooter";
import CursorSlider from "../../../component/CustomSlide/chalersslider";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fiddlerchalet from '../../../Assets/Icons/texture-chalet.png';
import BackgroundImage from "../../../component/backgroundtext/resortsbg";
import { useNavigate } from 'react-router-dom';
import Amenities from '../../../component/Amenities/Amenities';
import Exclusive from '../../../component/CustomSlide/Exslider';
import { useMedia } from '../../../MediaProvider';

function Resortactivities() {

    const { media } = useMedia();
        const Icons = media.Icons

    const slides = [

        {
            image: Icons.Activities1,
    
        },
        {
            image: Icons.Activities3,
    
        },
       
        {
            image: Icons.resort2,
    
        },
        {
            image: Icons.Activities2,
    
        },
     
    ];
    
  
    const sectionimg = {
        backgroundImage: 'url(' + fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",
    
    };
    const Holidaydata = [
        {
            birthdayimg: Icons.About3,
            familytype: "Discover the Fiddler Lake experience",
            link: "/experience",
            name: "Exclusive activities",
            titles: "Take in the natural beauty of the region with a peaceful canoe or kayak ride on the 2.8 km<sup>2</sup> lake or simply explore the acres of lush forest. Have fun in our Recreation Centre or hit some balls on the tennis courts. If relaxation is more your style, unwind in our heated indoor pool, available year-round, or our heated outdoor pool, available during the summer. Our wide range of activities is sure to make your stay unforgettable."
        }
        ,
    ]

    const [responsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "70vh" : "30vh"
    );
    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "70vh" : "30vh";
            setResponsiveHeight(newHeight);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const Depending = [{
        name: " ",
        height: "100vh",
        bgimg: Icons.ResortActivities,
        Rentals: "resort activities",
        width: "1200px",
    }]
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/discoverchalets');
      };
    return (
        <div>
            <section className='ACTIVITIES_data family_fillte'>
                <BackgroundImage Depending={Depending} lineRemove={true} />
            </section>
            <section className=" bg-skyYellow md:pt-20 md:pb-20 max-[767px]:py-10">
                <div className="container">
                <p className="text-20 AkagiPro-Black text-center"><div dangerouslySetInnerHTML={{ __html: t("Enjoy the natural beauty of Fiddler Lake Resort with a peaceful canoe or kayak ride on Fiddler Lake or simply explore the acres of lush forest, a few steps away from your chalet. Our vast array of on-site activities is guaranteed to provide fun and adventure, making your stay with us unforgettable!") }} /></p>
                </div>
            </section>
            <section className="Directly md:my-14 max-[767px]:my-[25px] relative">
                <Exclusive slides={slides} />
            </section>
            <section className='w-full bg-skyYellow pt-10 pb-14 md:my-16   max-[767px]:mt-[25px] max-[767px]:mb-[25px] Amenities_h' style={sectionimg}>
                <Amenities />
            </section>
            <section className="bg-lightyellow md:py-20 max-[767px]:pb-12 max-[767px]:py-3" style={{ backgroundImage: 'url(' + Icons.groups5 + ')', backgroundSize: "100%",display:"none" }}>
                <div className="container">
                    {Holidaydata.map((items,index) => (
                        <div key={index.toString()}>
                            <div> </div>
                            {items.familytype == "Discover the Fiddler Lake experience" && (
                                <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                    <div className="flex justify-end items-center max-[767px]:py-8  max-[767px]:pb-12">
                                        <div className="bg-white  relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-10 max-[767px]:-mr-[10px] md:-mr-[16px]" style={{ maxWidth: "569px" }}>
                                            <h2 className="text-right text-32 Akagi-Pro-blacks  md:mt-6 max-[767px]:mt-2 capitalize ">  {t(items.name)}</h2>
                                            <p className=" text-justify text-20 text-gray AkagiPro-book" dangerouslySetInnerHTML={{ __html: t(items.titles) }} />
                                            <Link to={items.link}  >  <button className="bg-black py-2 black_shoadow uppercase  an_button  max-[767px]:px-3 md:px-8  absolute -bottom-5 right-0  text-white text-20 AkagiPro-Black ">
                                                {t(items.familytype)}</button></Link>
                                        </div>
                                    </div>
                                    <div className="border-l-2 border-white md:pl-5 pl-2 max-[767px]:py-5">
                                        <div className="max-[767px]:h-full  max-[767px]:h-full  object-cover animation_img">
                                            <img loading="lazy" src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:h-full  object-cover img_animation" />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {items.familytype == "Explore the region’s activities and restaurants" && (
                                <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                    <div className="max-[767px]:pt-5 max-[767px]:pb-5">
                                        <div className=" animation_img max-[767px]:h-full max-[767px]:w-full object-cover">
                                            <img loading="lazy" src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:w-full object-cover img_animation" /></div>
                                    </div>
                                    <div className="border-l-2 md:pt-16  border-white md:mt-0 flex justify-start items-center " >
                                        <div className="bg-white md:-mt-20    max-[767px]:mt-8 max-[767px]:mb-12    relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-8 md:pb-16 max-[386px]:pb-12" style={{ maxWidth: "569px" }}>
                                            <h2 className="text-32 Akagi-Pro-blacks ">  {t(items.name)}</h2>
                                            <p className="text-20 AkagiPro-book text-justify text-gray ">{t(items.titles)}</p>
                                            <Link to={items.link}  >  <button className="bg-black uppercase an_button py-2 black_shoadow   max-[767px]:px-3 md:px-8 absolute -bottom-5 left-0  text-white text-20 AkagiPro-Black ">
                                                {t(items.familytype)}</button></Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            <section className="text-center container  px-8 md:mt-4 max-[767px]:mt-1">
               <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">{t("Discover our chalets")}</h1>
                <p className="text-20 AkagiPro-book md:mt-4">{t("Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ.")}</p>
                <p className="text-20 AkagiPro-book md:mt-4" dangerouslySetInnerHTML={{ __html: t("Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool.") }} />
                <p className="text-20 AkagiPro-book md:mt-4">{t("Read each model’s description for specific features and detailed information.")}</p>

            </section>
            <section className='relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ' >
                <div>
                    <CursorSlider  handleRedirect={handleRedirect} />
                </div>
            </section>
            <Bookfooter />
        </div>
    )
}
export default Resortactivities;